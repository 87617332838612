// Community.js
import React from 'react';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import comunidad from '../images/comunidad.jpg';

const Community = () => {
  const cardStyle = {
    backgroundColor: 'transparent', // Fondo transparente
    border: '2px solid white',     // Borde blanco
    color: 'white',                // Texto blanco
  };

  return (
    <Container className="my-5 d-flex justify-content-center align-items-center">
      <Card style={cardStyle}>
        <Card.Body className="text-center">
          <Card.Title>Comunidad</Card.Title>
          <Card.Text>
            ¡Saludos, apasionados emprendedores! En Emprendedores Anónimos, estamos emocionados de invitarte a formar parte de nuestro exclusivo grupo de WhatsApp. Aquí, el networking cobra vida, los eventos se comparten, la información fluye y todos contribuimos al crecimiento mutuo. ¿Listo para sumergirte en la energía empresarial?
          </Card.Text>
          {/* Agrega la imagen después del texto y aplica la clase img-fluid */}
          <img src={comunidad} alt="Descripción de la imagen" className="img-fluid" />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Community;

