import React from 'react';
import { Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const Footer = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="custom-footer">
      <Navbar.Text className="ml-auto">
        © {new Date().getFullYear()} Emprendedores Anónimos - Todos los derechos reservados
      </Navbar.Text>
    </Navbar>
  );
};

export default Footer;
