// App.js
import React from 'react';
import './App.css'; // Importa tu archivo de estilos
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/landing';
import Eventos from './pages/eventos';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/eventos" element={<Eventos />} />
        {/* Agrega otras rutas según sea necesario */}
      </Routes>
    </Router>
  );
}

export default App;



