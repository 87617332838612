import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import '../style/eventbox.css';

const EventBox = ({
  nombreEvento,
  imageSrc,
  contactNumber,
  organizer,
  company,
  date,
  mail,
  time,
  ubicacion,
  eventDetails,
  inscripcion,
}) => {
  return (
    <div className="event-box-container" style={{maxWidth: '1200px', display: 'flex', justifyContent: 'center'}}>
      <Card>
        <Row className="no-gutters">
          <Col md={3}>
            <Card.Img src={imageSrc} alt="Evento" />
          </Col>
          <Col md={4}>
            <Card.Body>
              <Card.Title>{nombreEvento}</Card.Title>
              <div className="event-details" style={{width: '100%', wordWrap: 'break-word'}}>
                <p><strong>Contacto:</strong> {contactNumber}</p>
                <p><strong>Organizador:</strong> {organizer}</p>
                <p><strong>Mail:</strong> {mail}</p>
              </div>
            </Card.Body>
          </Col>
          <Col md={4}>
            <Card.Body>
              <div className="event-details" style={{width: '100%', wordWrap: 'break-word'}}>
                <p><strong>Empresa:</strong> {company}</p>
                <p><strong>Fecha:</strong> {date}</p>
                <p><strong>Hora:</strong> {time}</p>
                <p><strong>Ubicacion: </strong> {ubicacion}</p>
              </div>
            </Card.Body>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col md={12}>
            <Card.Body>
              <div className="event-details" style={{width: '100%', wordWrap: 'break-word'}}>
                <p><strong>Descripcion del evento: </strong> {eventDetails}</p>
                <p><strong>Formulario de inscripcion :</strong>  <a href={inscripcion}>Click Aqui</a></p>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EventBox;
