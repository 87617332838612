import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../style/header.css';
import logoImage from '../images/logoea.png'; 

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="custom-header">
      <Navbar.Brand as={Link} to="/">
        <div className="logo-container">
          <img src={logoImage} className="logo-image" alt="Logo" />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="text-center">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/eventos">Eventos</Nav.Link>
          <Nav.Link href="https://www.instagram.com/emprendedores_anonimos_/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
          </Nav.Link>
          <Nav.Link href="https://chat.whatsapp.com/FslNU1WLkLD0lq5DZjBqKo" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
