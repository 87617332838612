// Objectives.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagenConexiones from '../images/conexionesSignificativas.jpg'; // Ajusta la ruta de la imagen
import imagenRecursos from '../images/recursos.jpg'; // Ajusta la ruta de la imagen

const Objectives = () => {
  const cardStyle = {
    border: '2px solid white',
    borderRadius: '15px',
    backgroundColor: 'rgba(255, 255, 255, 0)', // Fondo transparente
    marginBottom: '20px',
    color: 'white',
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={6} className="d-flex">
          <Card className="mb-4 h-100" style={cardStyle}>
            <Card.Img variant="top" src={imagenConexiones} alt="Conexiones Significativas" style={{ borderRadius: '15px 15px 0 0' }} />
            <Card.Body className="d-flex flex-column justify-content-between">
              <Card.Title>Conexiones Significativas:</Card.Title>
              <Card.Text>
                En Emprendedores Anónimos, no simplemente creemos en la red de contactos; abrazamos la creación de vínculos que trascienden lo profesional y se arraigan en una comprensión profunda y mutua de las aspiraciones y desafíos empresariales.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card className="mb-4 h-100" style={cardStyle}>
            <Card.Img variant="top" src={imagenRecursos} alt="Recursos En Abundancia" style={{ borderRadius: '15px 15px 0 0' }} />
            <Card.Body className="d-flex flex-column justify-content-between">
              <Card.Title>Recursos En Abundancia</Card.Title>
              <Card.Text>
                Accede a una rica fuente de información, eventos y oportunidades exclusivas para potenciar tu empresa.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Objectives;
