// Landing.js
import React from 'react';
import Header from '../components/header';
import ImageCarousel from '../components/carousel';
import CardCarousel from '../components/cardcarousel';
import AboutUs from '../components/aboutUS';
import Objectives from '../components/objetives';
import Community from '../components/community';
import Footer from '../components/footer';
import RegistrationForm from '../components/registrationForm';
import TitleBox from '../components/title';
import UserCountBox from '../components/UserCountBox';

const Landing = () => {
  return (
    <div className="App">
      {/* Asegúrate de que el fondo esté en el contenedor principal */}
      <div className="app-container">
        <Header />
        <TitleBox text="¡Somos el Ruido!" />
        <ImageCarousel />
        <TitleBox text="Emprendedores Anónimos" />
        <CardCarousel />
        <UserCountBox></UserCountBox>
        <AboutUs />
        <Objectives />
        <Community />
        <RegistrationForm />
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
