// TitleBox.js
import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const TitleBox = ({ text }) => {
  return (
    <Container className="my-5">
      <div className="text-center text-white"> {/* Añadido text-white para el color blanco */}
        <h1 className="display-4">{text}</h1>
      </div>
    </Container>
  );
};

export default TitleBox;