// firebaseFunctions.js
import { db, auth, storage } from './firebaseConfig';
import { collection, addDoc, getDocs, query, orderBy, where } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPhoneNumber  } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';



export const guardarDatosConImagen = async (nombre, rol, whatsapp, correo, foto) => {
  try {
    // Agregar datos a Firestore
    const docRef = await addDoc(collection(db, 'usuarios'), {
      Nombre: nombre,
      Rol: rol,
      Whatsapp: whatsapp,
      Correo: correo,
    });

    console.log('Document written with ID: ', docRef.id);

    // Subir imagen a Storage
    const storageRef = ref(storage, `usuarios/${docRef.id}/foto`);
    await uploadBytes(storageRef, foto);

    console.log('Imagen subida correctamente.');

    // Obtener la URL de descarga de la imagen
    const url = await getDownloadURL(storageRef);

    console.log('URL de la imagen:', url);

    return url; // Devolver la URL si es necesario
  } catch (error) {
    console.error('Error adding document: ', error);
    throw error;
  }
};

export const registrar = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error('Error registering user: ', error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error('Error logging in: ', error);
    throw error;
  }
};
export const obtenerDatosParaTarjetas = async () => {
    try {
      // Obtener todos los documentos de la colección "usuarios"
      const querySnapshot = await getDocs(collection(db, 'usuarios'));
  
      // Inicializar un arreglo para almacenar los datos
      const cardData = [];
  
      // Iterar sobre los documentos y construir el arreglo
      querySnapshot.forEach((doc) => {
        const { Nombre, Rol } = doc.data();
  
        // Formar la URL de descarga de la imagen
        const storageRef = ref(storage, `usuarios/${doc.id}/foto`);
        const imgSrc = getDownloadURL(storageRef);
  
        // Agregar la información al arreglo
        cardData.push({ imgSrc, title: Nombre, subtitle: Rol });
      });
  
      return cardData;
    } catch (error) {
      console.error('Error fetching user data: ', error);
      throw error;
    }
  };


export const obtenerCantidadUsuarios = async () => {
  try {
    // Obtener todos los documentos de la colección "usuarios"
    const querySnapshot = await getDocs(collection(db, 'usuarios'));

    // Obtener la cantidad de usuarios
    const cantidadUsuarios = querySnapshot.size;

    return cantidadUsuarios;
  } catch (error) {
    console.error('Error fetching user count: ', error);
    throw error;
  }
};

export const guardarDatosEventoConImagen = async (nombre, whatsapp, org, fecha, hora, empresa, correo, detalles, foto, registrationForm, ubicacion) => {
  try {
    // Agregar datos a Firestore
    const docRef = await addDoc(collection(db, 'eventos'), {
      Nombre: nombre,
      Whatsapp: whatsapp,
      Rol: org, // Cambiado de 'role' a 'org'
      Fecha: fecha,
      Hora: hora,
      Empresa: empresa,
      Correo: correo,
      Detalles: detalles,
      FormularioInscripcion: registrationForm,
      Ubicacion: ubicacion,
    });

    console.log('Documento escrito con ID: ', docRef.id);

    // Subir imagen a Storage
    const storageRef = ref(storage, `eventos/${docRef.id}/foto`);
    await uploadBytes(storageRef, foto);

    console.log('Imagen subida correctamente.');

    // Obtener la URL de descarga de la imagen
    const url = await getDownloadURL(storageRef);

    console.log('URL de la imagen:', url);

    return url; // Devolver la URL si es necesario
  } catch (error) {
    console.error('Error al agregar el documento: ', error);
    throw error;
  }
};



export const obtenerFechasDeEventos = async () => {
  const eventosCollection = collection(db, 'eventos');
  const eventosSnapshot = await getDocs(eventosCollection);

  const fechas = [];

  eventosSnapshot.forEach((doc) => {
    const fechaEvento = doc.data().Fecha; // Ajusta 'date' según el nombre del campo en tus documentos

    // Aquí necesitas convertir tu formato de fecha a un objeto Date
    // Puedes hacer esto según la estructura de tu fecha, por ejemplo, si es una cadena en formato 'YYYY-MM-DD':
    const partesFecha = fechaEvento.split('-');
    const fecha = new Date(parseInt(partesFecha[0]), parseInt(partesFecha[1]) - 1, parseInt(partesFecha[2]));

    fechas.push(fecha);
  });

  return fechas;
};


export const obtenerEventosConImagenes = async () => {
  try {
    // Crear una consulta para la colección "eventos" ordenada por el campo "Fecha"
    const eventosQuery = query(collection(db, 'eventos'), orderBy('Fecha'));

    // Obtener todos los documentos de la colección "eventos" ordenados por fecha
    const querySnapshot = await getDocs(eventosQuery);

    // Inicializar un arreglo para almacenar los datos de eventos
    const eventosData = [];

    // Iterar sobre los documentos y construir el arreglo
    for (const doc of querySnapshot.docs) {
      const { Nombre, Whatsapp, Rol, Fecha, Hora, Empresa, Correo, Detalles, FormularioInscripcion, Ubicacion } = doc.data();

      // Obtener la URL de descarga de la imagen
      const storageRef = ref(storage, `eventos/${doc.id}/foto`);
      const imgUrl = await getDownloadURL(storageRef);

      // Agregar la información al arreglo
      eventosData.push({
        Nombre,
        Whatsapp,
        Rol,
        Fecha,
        Hora,
        Empresa,
        Correo,
        Detalles,
        FormularioInscripcion,
        Ubicacion,
        imgUrl,
      });
    }

    return eventosData;
  } catch (error) {
    console.error('Error al obtener datos de eventos: ', error);
    throw error;
  }
};
export const obtenerEventosPorFecha = async (fechaEspecifica) => {
  try {
    const eventosQuery = query(
      collection(db, 'eventos'),
      where('Fecha', '==', fechaEspecifica),
      orderBy('Fecha')
    );

    const querySnapshot = await getDocs(eventosQuery);

    const eventos = await Promise.all(querySnapshot.docs.map(async (doc) => {
      const { Nombre, Whatsapp, Rol, Fecha, Hora, Empresa, Correo, Detalles, FormularioInscripcion, Ubicacion } = doc.data();

      const storageRef = ref(storage, `eventos/${doc.id}/foto`);
      const imgUrl = await getDownloadURL(storageRef);

      return {
        id: doc.id,
        Nombre,
        Whatsapp,
        Rol,
        Fecha,
        Hora,
        Empresa,
        Correo,
        Detalles,
        FormularioInscripcion,
        Ubicacion,
        imgUrl,
      };
    }));

    // Ordenar eventos localmente por Hora
    const eventosOrdenados = eventos.sort((a, b) => a.Hora.localeCompare(b.Hora));

    return eventosOrdenados;
  } catch (error) {
    console.error('Error al obtener eventos por fecha: ', error);
    throw error;
  }
};





