import React from 'react';
import { Carousel, Container, Image } from 'react-bootstrap';
import carouselImage1 from '../images/comunidad.jpg';
import carouselImage2 from '../images/comunidad1.jpg';
import carouselImage3 from '../images/comunidad2.jpg';

const ImageCarousel = () => {
  return (
    <Container className="position-relative">
      {/* Logo y texto fijos */}
      
      {/* Carrusel de imágenes */}
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={carouselImage1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carouselImage2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={carouselImage3} alt="Third slide" />
        </Carousel.Item>
        {/* Agrega más items según sea necesario */}
      </Carousel>
    </Container>
  );
};

export default ImageCarousel;
