import React from 'react';
import EventBox from './EventBox';

const EventList = ({ eventos }) => {
  if (!eventos || eventos.length === 0) {
    return <p>No hay eventos para hoy.</p>;
  }

  return (
    <div>
      {eventos.map((evento, index) => (
        <EventBox
          key={index}
          nombreEvento={evento.Nombre}
          imageSrc={evento.imgUrl}
          contactNumber={evento.Whatsapp}
          organizer={evento.Rol}
          company={evento.Empresa}
          date={evento.Fecha}
          mail={evento.Correo}
          time={evento.Hora}
          ubicacion={evento.Ubicacion}
          eventDetails={evento.Detalles}
          inscripcion={evento.FormularioInscripcion}
        />
      ))}
    </div>
  );
};

export default EventList;
