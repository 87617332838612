import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { guardarDatosConImagen } from './firebase/firebaseFunctions';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    role: '',
    email: '',
    whatsapp: '',
    photo: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      photo: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    if (!formData.name || !formData.role || !formData.email || !formData.whatsapp || !formData.photo) {
      console.error('Por favor, completa todos los campos antes de enviar.');
      return;
    }

    // Evitar envíos múltiples
    if (isSubmitting) {
      return;
    }

    // Iniciar el proceso de envío
    setIsSubmitting(true);

    try {
      // Guardar datos y foto en Firestore y Storage
      await guardarDatosConImagen(
        formData.name,
        formData.role,
        formData.whatsapp,
        formData.email,
        formData.photo
      );

      console.log('Datos y foto guardados con éxito.');
    } catch (error) {
      console.error('Error al guardar datos y foto: ', error);
    } finally {
      // Restablecer el estado del formulario y detener el proceso de envío
      setIsSubmitting(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      role: '',
      email: '',
      whatsapp: '',
      photo: null,
    });

    // Restablecer el valor del campo de selección de imagen
    const fileInput = document.getElementById('formPhoto');
    if (fileInput) {
      fileInput.value = ''; // Esto restablecerá el campo de selección de archivo
    }
  };

  return (
    <Container
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0)',
        border: '2px solid white',
        borderRadius: '10px',
        padding: '20px',
        margin: '20px 0',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
      }}
      className="mx-auto"
    >
      <Form onSubmit={handleSubmit} className="w-100">  
          <Form.Group controlId="formName" className="w-100 text-center">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa tu nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formRole" className="w-100 text-center">
          <Form.Label>Rol Profesional</Form.Label>
          <Form.Control
            type="text"
            placeholder="CEO / Profesion / Servicio"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="w-100 text-center">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingresa tu correo electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formWhatsapp" className="w-100 text-center">
          <Form.Label>Número de WhatsApp</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Ingresa tu número de WhatsApp"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formPhoto" className="w-100 text-center">
          <Form.Label>Foto de perfil</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-100"
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100 mt-3"
          style={{
            fontSize: '1.2em',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            border: '2px solid white',
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </Button>
      </Form>
    </Container>
  );
};

export default RegistrationForm;
