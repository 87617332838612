import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { guardarDatosEventoConImagen } from './firebase/firebaseFunctions';

const FormularioEvent = () => {
  const [formData, setFormData] = useState({
    name: '',
    whatsapp: '',
    org: '', // Cambiado de 'role' a 'org'
    date: '',
    time: '',
    company: '',
    email: '',
    details: '',
    photo: null,
    registrationForm: '',
    location: '', // Agregar el nuevo campo para la ubicación
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      photo: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    if (
      !formData.name ||
      !formData.whatsapp ||
      !formData.org ||
      !formData.date ||
      !formData.time ||
      !formData.company ||
      !formData.email ||
      !formData.details ||
      !formData.photo ||
      !formData.registrationForm ||
      !formData.location
    ) {
      console.error('Por favor, completa todos los campos antes de enviar.');
      return;
    }

    // Evitar envíos múltiples
    if (isSubmitting) {
      return;
    }

    // Iniciar el proceso de envío
    setIsSubmitting(true);

    try {
      // Guardar datos y foto en Firestore y Storage usando la nueva función
      
      //guardarDatosEventoConImagen = async (nombre, whatsapp, org, fecha, hora, empresa, correo, detalles, foto, registrationForm, ubicacion)
      
      const imageUrl = await guardarDatosEventoConImagen(
        formData.name,
        formData.whatsapp,
        formData.org, // Cambiado de 'role' a 'org'
        formData.date,
        formData.time,
        formData.company,
        formData.email,
        formData.details,
        formData.photo,
        formData.registrationForm,
        formData.location,
      );

      console.log('Datos y foto guardados con éxito.');

      // Aquí podrías utilizar imageUrl si es necesario

      // Mostrar el enlace al formulario de inscripción
      console.log('Enlace al formulario de inscripción:', formData.registrationForm);
    } catch (error) {
      console.error('Error al guardar datos y foto: ', error);
    } finally {
      // Restablecer el estado del formulario y detener el proceso de envío
      setIsSubmitting(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      whatsapp: '',
      org: '',
      date: '',
      time: '',
      company: '',
      email: '',
      details: '',
      photo: null,
      registrationForm: '',
      location: '',
    });

    // Restablecer el valor del campo de selección de imagen
    const fileInput = document.getElementById('formPhoto');
    if (fileInput) {
      fileInput.value = ''; // Esto restablecerá el campo de selección de archivo
    }
  };
//Se esta guardando mal el contacto el organizador el mail 
  return (
    <Container
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0)',
        border: '2px solid white',
        borderRadius: '10px',
        padding: '20px',
        margin: '20px 0',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
      }}
      className="mx-auto"
    >
      <Form onSubmit={handleSubmit} className="w-100">  
        <Form.Group controlId="formName" className="w-100 text-center">
          <Form.Label>Nombre del Evento</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa el nombre del evento"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formRole" className="w-100 text-center">
          <Form.Label>Contacto Teléfono</Form.Label>
          <Form.Control
            type="tel"
            placeholder="+569xxxxxxxx"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formOrg" className="w-100 text-center">
          <Form.Label>Organizador</Form.Label>
          <Form.Control
            type="text"
            placeholder="Empresa o persona que organiza el evento"
            name="org"
            value={formData.org}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formDate" className="w-100 text-center">
          <Form.Label>Fecha</Form.Label>
          <Form.Control
            type="date"
            placeholder="Fecha del evento"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formTime" className="w-100 text-center">
          <Form.Label>Hora</Form.Label>
          <Form.Control
            type="time"
            placeholder="Hora del evento"
            name="time"
            value={formData.time}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formCompany" className="w-100 text-center">
          <Form.Label>Empresa</Form.Label>
          <Form.Control
            type="text"
            placeholder="Empresa o persona que organiza el evento"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="w-100 text-center">
          <Form.Label>Correo de Contacto</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingresa Correo de Contacto"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formDetails" className="w-100 text-center">
          <Form.Label>Detalles</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ingresa detalles del evento"
            name="details"
            value={formData.details}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formRegistrationForm" className="w-100 text-center">
          <Form.Label>Formulario de Inscripción (URL)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el enlace al formulario de inscripción"
            name="registrationForm"
            value={formData.registrationForm}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formLocation" className="w-100 text-center">
          <Form.Label>Ubicación</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese la ubicación del evento"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="w-100 placeholder-center"
          />
        </Form.Group>

        <Form.Group controlId="formPhoto" className="w-100 text-center">
          <Form.Label>Flyer del Evento / Logo Empresa</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-100"
          />
        </Form.Group>


        <Button
          variant="primary"
          type="submit"
          className="w-100 mt-3"
          style={{
            fontSize: '1.2em',
            backgroundColor: 'rgba(255, 255, 255, 0)',
            border: '2px solid white',
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </Button>
      </Form>

    </Container>
  );
};

export default FormularioEvent;
