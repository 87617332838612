import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../style/calendar.css'

const MyCalendar = ({ highlightedDates, onDateChange }) => {
  const [date, setDate] = useState(new Date());

  const tileClassName = ({ date, view }) => {
    // Comprueba si la fecha actual se encuentra en la lista de fechas resaltadas
    if (highlightedDates.some((highlightedDate) => date.toDateString() === highlightedDate.toDateString())) {
      return 'highlighted';
    }
  };

  const onChange = (newDate) => {
    setDate(newDate); 
    if (typeof onDateChange === 'function') {
      onDateChange(newDate); // Llama a la función proporcionada desde Eventos
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Calendar onChange={onChange} value={date} tileClassName={tileClassName} />
    </div>
  );
};
export default MyCalendar;




