import React, { useState, useEffect } from 'react';
import MyCalendar from '../components/calendar';
import TitleBox from '../components/title';
import Header from '../components/header';
import Footer from '../components/footer';
import { Row, Col } from 'react-bootstrap';
import FormularioEvent from '../components/formularioEvent';
import EventList from '../components/eventList';
import { obtenerFechasDeEventos, obtenerEventosConImagenes, obtenerEventosPorFecha } from '../components/firebase/firebaseFunctions';
import '../style/eventos.css'

const Eventos = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([]);


  const [eventData, setEventData] = useState([]);


  const filterPastEvents = (events) => {
    const currentDate = new Date();
  
    // Filtra los eventos cuya fecha sea igual o posterior a la fecha actual
    const filteredEvents = events.filter((event) => {
      const eventDate = new Date(event.Fecha);
      return eventDate >= currentDate;
    });
  
    return filteredEvents;
  };


  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const eventos = await obtenerEventosConImagenes();
        const filteredEvents = filterPastEvents(eventos);
        console.log(filteredEvents);
        setEventData(filteredEvents);
      } catch (error) {
        console.error('Error al obtener eventos: ', error);
      }
    };
  
    fetchEventos();
  }, []); // Asegúrate de pasar un arreglo vacío como dependencia para evitar ciclos infinitos
  
  const handleDateChange = async (newDate) => {
    setSelectedDate(newDate);
    const formattedDate = newDate.getFullYear() + '-' +
      ('0' + (newDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + newDate.getDate()).slice(-2);
  
    try {
      const eventosFechaEspecifica = await obtenerEventosPorFecha(formattedDate);
      console.log('Eventos de la fecha seleccionada:', eventosFechaEspecifica);
  
      // Actualiza el estado de los eventos con los eventos de la fecha seleccionada
      setEventData(eventosFechaEspecifica);
    } catch (error) {
      console.error('Error al obtener eventos por fecha: ', error);
    }
  
    alert(`Fecha seleccionada: ${formattedDate}`);
    // Otras lógicas que desees realizar después de obtener los eventos
  };
  

  useEffect(() => {
    obtenerFechasDeEventos().then((fechas) => {
      console.log(fechas)
      setHighlightedDates(fechas);
    });
  }, []);

  return (
    <div>
      <Header />
      <TitleBox text="Networking" />
      <MyCalendar highlightedDates={highlightedDates} onDateChange={handleDateChange} />
      <TitleBox text="Listas de Eventos" />
      <Row className="justify-content-center">
        <Col md={8}>
          <EventList eventos={eventData} />
        </Col>
      </Row>
      <FormularioEvent />
      <Footer />
    </div>
  );
};

export default Eventos;
