// AboutUs.js
import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  const [iframeWidth, setIframeWidth] = useState(560);

  const handleResize = () => {
    const newWidth = window.innerWidth >= 768 ? 560 : 800;
    setIframeWidth(newWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container fluid className="d-flex justify-content-center align-items-center mt-4">
      <Container className="my-5 mx-auto mb-5">
        <Card className="embed-responsive embed-responsive-16by9" style={{ background: 'transparent', border: '2px solid #ddd', borderRadius: '15px' }}>
          <Card.Body className="text-center" style={{ color: 'white' }}>
            <Card.Title>¿Quiénes Somos?</Card.Title>
            <Card.Text>
              Somos más que una comunidad; somos un grupo de mentes creativas y corazones audaces. Nos encontramos en la intersección de sueños y ejecución, donde las ideas se nutren con la sabiduría colectiva y el apoyo mutuo. En Emprendedores Anónimos, celebramos la diversidad de experiencias y habilidades, reconociendo que cada miembro aporta un valor único a nuestro vibrante tejido empresarial.
            </Card.Text>
            <div className="embed-responsive embed-responsive-16by9 mt-4 d-flex justify-content-center align-items-center" style={{ maxWidth: '800px', maxHeight: '450px', margin: '0 auto' }}>
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/f2puDs-PzVA?si=XjyteQvtnKXb9cyJ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                width="100%"
              ></iframe>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </Container>
  );
};

export default AboutUs;





