import React, { useEffect, useState } from 'react';
import { Carousel, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { obtenerDatosParaTarjetas, obtenerCantidadUsuarios } from './firebase/firebaseFunctions'; // Ajusta la ruta según la ubicación de tu archivo
const CardCarousel = () => {
  const [cardData, setCardData] = useState([]);
  
  
  //-----------
// ...
useEffect(() => {
  // Llamada a obtenerDatosParaTarjetas
  obtenerDatosParaTarjetas().then((data) => {
    // Mapear los datos y obtener las URLs de las imágenes
    const promises = data.map(async (card) => {
      const url = await card.imgSrc; // Esperar a que se resuelva la promesa
      return { ...card, imgSrc: url }; // Devolver el objeto actualizado
    });

    // Esperar a que todas las promesas se resuelvan
    Promise.all(promises).then((resolvedData) => {
      console.log('Datos para tarjetas:', resolvedData);
      setCardData(resolvedData);
    });
  });

    // Llamada a obtenerCantidadUsuarios
    obtenerCantidadUsuarios().then((cantidadUsuarios) => {
      // Imprimir la cantidad de usuarios
      console.log('Cantidad de usuarios:', cantidadUsuarios);
    });
  
}, []);
// ...


  //------------------
  const containerStyle = {
    border: '2px solid #fff', // Borde blanco
    borderRadius: '15px',
    marginTop: '100px',
    marginBottom: '200px',
    padding: '20px',
  };
  
  const cardStyle = {
    width: '200px',
    height: '300px',
    borderRadius: '20px',
    marginRight: '20px',
    marginTop: '10px',
    backgroundColor: 'transparent',
    border: '1px solid #fff', // Borde blanco
  };

  const imageStyle = {
    borderRadius: '15px',
    height: '150px',
    objectFit: 'cover',
  };

  const textStyle = {
    color: 'white',
    textAlign: 'center', // Textos centrados
  };

  return (
    <Container style={containerStyle}>
      <Carousel wrap={true} interval={4000}>
        {[...Array(Math.ceil(cardData.length / 4)).keys()].map((index) => (
          <Carousel.Item key={index}>
            <div className="d-flex flex-wrap justify-content-center">
              {cardData.slice(index * 4, (index + 1) * 4).map((card, cardIndex) => (
                <Card key={cardIndex} className="mb-4" style={cardStyle}>
                  <Card.Img variant="top" src={card.imgSrc} style={imageStyle} />
                  <Card.Body>
                    <Card.Title style={textStyle}>{card.title}</Card.Title>
                    <Card.Text style={textStyle}>{card.subtitle}</Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default CardCarousel;
