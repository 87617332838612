// UserCountBox.js
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { obtenerCantidadUsuarios } from './firebase/firebaseFunctions'; // Ajusta la ruta según la ubicación de tu archivo

const UserCountBox = () => {
  const [cantidadUsuarios, setCantidadUsuarios] = useState(null);

  useEffect(() => {
    // Llamada a la función para obtener la cantidad de usuarios
    obtenerCantidadUsuarios().then((cantidad) => {
      setCantidadUsuarios(cantidad);
    });
  }, []);

  return (
    <Container className="my-5">
      <div className="text-center text-white">
        <h1 className="display-4">
          {cantidadUsuarios !== null ? cantidadUsuarios : 'Cargando...'}
        </h1>
        <p>Cantidad de Usuarios</p>
      </div>
    </Container>
  );
};

export default UserCountBox;
